

const RightSection: React.FC = () => {
	return (
		<>
			<img className="img-fluid floating-animate" src="/images/all-anime-characters-img.webp" alt="all anime characters img" />
		</>
	);
};

export default RightSection;
