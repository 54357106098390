const GithubIcon: React.FC = () => {
	return (
		<svg
			width="17"
			height="20"
			viewBox="0 0 17 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="icon"
		>
			<path
				d="M6.4174 17.1432C3.05157 17.9068 2.33033 15.7148 2.33033 15.7148C1.76464 14.3006 0.986822 13.947 0.986822 13.947C-0.0314113 13.2116 1.12824 13.2399 1.12824 13.2399C2.35861 13.3389 2.96672 14.5127 2.96672 14.5127C4.12638 16.2946 5.76686 15.8987 6.53054 15.531"
				fill="currentColor"
				// style={{ transformOrigin: "130px 110px" }}
				className="octo-arm"
			/>
			<path
				d="M5.0172 19.7595C5.0172 19.7595 5.75259 19.4766 5.76673 19.1797L5.95058 15.1209C6.03543 14.3572 6.37485 13.8481 6.7284 13.5794C4.01311 13.1268 1.21297 12.0237 1.35439 7.35682C1.39681 6.06989 1.94836 4.95266 2.74032 4.1607C2.5989 3.84958 2.21706 2.61921 2.89588 0.978722C2.89588 0.978722 3.91411 0.724165 6.191 2.35051C7.18095 2.12424 8.21332 2.02524 9.27398 2.03938C10.2356 2.06767 11.3529 2.22323 12.2297 2.56264C14.6339 1.00701 15.7369 1.31813 15.7369 1.31813C16.3875 2.93034 15.9632 4.20313 15.6804 4.48597C16.5006 5.30622 16.9107 6.36688 16.8117 7.73866C16.7552 12.3207 13.8419 13.2541 11.1832 13.4238C11.6074 13.8481 12.0317 14.5552 12.0034 15.7148L11.6781 19.1514C11.6781 19.4342 12.4984 19.8302 12.4984 19.7453L5.0172 19.7595Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default GithubIcon;
